import React from "react";
import { generate } from "short-uuid";
import getAppTenantIdTsg from "./utils/get-app-tenant-id-tsg";
import getAppTenantId from "./utils/get-app-tenant-id";
import getDIAppTenantRegion from "./utils/getAppTenantRegion";
import { HOST_APP_DI, HOST_APP_FAWKES } from "@reporting-n-viz/vnr-entrypoint/dist/host-app";
import { aiopsAuthDataMutation } from "./utils/aiops-authdata-mutation";
import "./refs/dashboards";

/* Warning: please do not statically import any dependencies here except for static assets like icons */

const init = ({ vars, onAuthed, sparkyFramework }) => {
    onAuthed((params) => {
        const setReportUuids = require("./utils/set-report-uuids").default;
        const reports = tryParse(process.env.REACT_APP_REPORTS, []);

        for (const report of reports) {
            const uuid =
                new URLSearchParams(window.location.search).get("report-uuid") || generate();

            setReportUuids(report.id, uuid);
        }

        params.getFeatures();
        params.getLicenseInfo();
        params.getFqdnInfo();

        return Promise.all([sparkyFramework.loaded, params.getAuthInfo()])
            .then(() => import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch"))
            .then(({ prefetch }) => {
                const getCsIdTsg = require("./utils/get-cs-id-tsg").default;
                const getCsId = require("./utils/get-cs-id").default;
                const getLsTenantIdTsg = require("./utils/get-ls-tenant-id-tsg").default;
                const getLsTenantId = require("./utils/get-ls-tenant-id").default;
                const getInstanceTier = require("./utils/get-instance-tier").default;
                const hasTsgSupport = sparkyFramework.hasTsgSupport();
                const main = sparkyFramework.getMainState() || {};
                const isPAEnabled = main?.tenant?.pa_enabled ?? false;
                let appTenantRegion = main?.tenant?.info?.region;
                let authData = hasTsgSupport
                    ? sparkyFramework.getAuthState()
                    : sparkyFramework.getAuthData(main.tenant, main.authInfo, main.loggedInUser);
                const isSDWANOnlyTenant =
                    authData?.instances?.length === 1 && authData?.instances[0]?.app_id === "cgx";
                const hasCDL = authData?.instances?.isRunningStatus("logging_service");
                const lsTenantLogData =
                    authData?.instances?.find((instance) => instance.app_id === "logging_service")
                        ?.extra.purchased_size > 0 ?? false;
                const currentInstanceTier = getInstanceTier();
                let managedAppName = sparkyFramework.StartupManager.getManagedAppName();
                let di = undefined;
                let prismaAccess = undefined;

                const appTenantIdPrismaAccess = hasTsgSupport
                    ? getAppTenantIdTsg(authData, "prisma_access")
                    : undefined;
                if (appTenantIdPrismaAccess && isPAEnabled) {
                    prismaAccess = {
                        appTenantId: appTenantIdPrismaAccess,
                        appTenantRegion,
                        appType: managedAppName,
                    };
                }

                const aiopsAppId =
                    currentInstanceTier === "premium" ? "strata_insights" : "strata_insights_free";
                if (
                    hasTsgSupport &&
                    (authData.instances.some(({ app_id }) => app_id === aiopsAppId) ||
                        isSDWANOnlyTenant)
                ) {
                    managedAppName = isSDWANOnlyTenant ? "cgx" : "strata_insights";
                    authData = aiopsAuthDataMutation(authData, currentInstanceTier);
                    appTenantRegion = getDIAppTenantRegion(managedAppName, authData);
                    di = {
                        appTenantId: getAppTenantIdTsg(authData, managedAppName),
                        appTenantRegion,
                        appType: managedAppName,
                    };
                }

                const hostApp = [
                    "strata_insights_free",
                    "strata_insights",
                    "strata_insights_ngfw",
                    "cgx",
                ].includes(managedAppName)
                    ? HOST_APP_DI
                    : HOST_APP_FAWKES;
                const appTenantId = hasTsgSupport
                    ? getAppTenantIdTsg(authData, managedAppName)
                    : getAppTenantId(main);

                return prefetch(
                    vars.api_server_url,
                    {
                        tokenManager: hasTsgSupport
                            ? null
                            : sparkyFramework.getTokenManager(
                                  main.tenant,
                                  main.authInfo,
                                  main.loggedInUser,
                              ),
                        csId: hasTsgSupport ? getCsIdTsg(authData, managedAppName) : getCsId(main),
                        tsgId: authData.tsg_id,
                        appsToTenantMap: new Map([[managedAppName, new Set([appTenantId])]]),
                        hasTsgSupport,
                        getCurrentTsgToken: sparkyFramework.getCurrentTsgToken,
                        hostApp,
                        managedAppName,
                        appTenantId,
                        authData,
                        loggedInUser: main.loggedInUser,
                        appTenantRegion: appTenantRegion || "us",
                        isEarlyAccessNgfwTenant: main.tenant.is_early_access_ngfw_tenant || false,
                        isSDWANOnlyTenant,
                        lsTenantId: hasTsgSupport
                            ? getLsTenantIdTsg(authData)
                            : getLsTenantId(main),
                        currentInstanceTier,
                        hasCDL,
                        onlyTelemetryTenant: lsTenantLogData,
                        di,
                        prismaAccess,
                    },
                    sparkyFramework.actions.setValues,
                );
            });
    });

    return import(/* webpackPreload: true */ "./routes").then((r) => r.default);
};

init.exposes = {
    UARComponent: () => import("./UAR"),
    Dashboard: () => import("./Dashboard"),
    CoPilot: () => import("./CoPilot"),
};

function tryParse(raw, defaultValue) {
    try {
        return JSON.parse(raw) || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export default init;
