import { getFrameworkVars } from "sparky-framework";

export const AIOPS_FREE_PATH = "/aiops-free";

function getInstanceTier() {
    return getFrameworkVars().aiops_free ? "free" : "premium";
}

/**
 * Check if instance tier is valid
 * @param {*} authData - auth date
 * @param {*} instanceTier - current instance tier
 * @info In One app we detect inctance tier based on path see getInstanceTier.
 *       There is chance that user with only free license can manually update url.
 *       This validator checks if user actually has strata_insights(premium) instance
 *       Also it returns true In case if we run in non AIOps context -
 *          when there are no strata_insights/strata_insights_free
 * @returns boolean
 */
export const isInstanceTierValid = (authData, instanceTier) => {
    if (instanceTier !== "premium") {
        return true;
    }
    let hasFreeInstance = false;
    let hasPremiumInstance = false;
    let hasPAInstance;

    authData?.instances?.forEach((instance) => {
        if (instance?.app_id === "strata_insights") {
            hasPremiumInstance = true;
        }
        if (instance?.app_id === "strata_insights_free") {
            hasFreeInstance = true;
        }
        if (instance?.app_id.startsWith("prisma_access")) {
            hasPAInstance = true;
        }
    });
    return hasPAInstance || hasPremiumInstance || !hasFreeInstance;
};
export default getInstanceTier;
