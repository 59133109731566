import Registry from "@reporting-n-viz/oneapp-extensibility";

const dashboards = [
    {
        key: "threat_insights_report",
        path: "/threat_insights",
        subPages: ["/threat_activity", "/security_subscription"],
        group: "activity",
        name: {
            id: "reports.threat_insights_report_title",
            defaultMessage: "Threat Insights",
        },
    },
    {
        key: "executive_report",
        path: "/executive",
        group: "activity",
        name: {
            id: "reports.executive_report_title",
            defaultMessage: "Executive Summary",
        },
    },
    {
        key: "app_usage_report",
        path: "/app_usage",
        group: "activity",
        name: {
            id: "reports.app_usage_report_title",
            defaultMessage: "Application Usage",
        },
    },
    {
        key: "prisma_access_report",
        path: "/usage",
        group: "activity",
        name: {
            id: "prisma_access_report_title",
            defaultMessage: "Prisma Access",
        },
    },
    {
        key: "user_activity_report",
        path: "/uar",
        group: "activity",
        name: {
            id: "user_activity_report_title",
            defaultMessage: "User Activity",
        },
    },
    {
        key: "wildfire_report",
        path: "/wildfire",
        group: "activity",
        subPages: ["/wildfire/samples"],
        name: {
            id: "reports.wildfire_report_title",
            defaultMessage: "WildFire",
        },
    },
    {
        key: "dns_report",
        path: "/dns",
        group: "activity",
        name: {
            id: "reports.dns_report_title",
            defaultMessage: "DNS Security",
        },
        subPages: ["/dns/dns_requests"],
    },
    {
        key: "advanced_url_filtering_report",
        path: "/advanced_url_filtering",
        group: "activity",
        name: {
            id: "reports.advanced_url_filtering_title",
            defaultMessage: "Advanced URL Filtering",
        },
    },
    {
        key: "advanced_threat_prevention_report",
        path: "/advanced_threat_prevention",
        group: "activity",
        name: {
            id: "reports.advanced_threat_prevention_title",
            defaultMessage: "Advanced Threat Prevention",
        },
    },
    {
        key: "executive_report_dlp_details_report",
        path: "/dlp_details",
        group: "activity",
        name: {
            id: "reports.executive_report_dlp_details_title",
            defaultMessage: "DLP Dashboard",
        },
    },
    {
        key: "network_activity_report",
        path: "/network_activity",
        group: "health",
        name: {
            id: "reports.network_activity_report_title",
            defaultMessage: "Network Usage",
        },
    },
    {
        key: "best_practices_report",
        path: "/bpa",
        group: "posture",
        name: {
            id: "best_practices_report_title",
            defaultMessage: "Best Practices",
        },
    },
    {
        key: "sase_advanced_detections_report",
        path: "/sase_advanced_detections",
        group: "activity",
        name: {
            id: "reports.sase_advanced_detections_report_title",
            defaultMessage: "SASE Advanced Detections",
        },
    }
];

dashboards.forEach((d) => {
    Registry.contributeExtension("vnr.ia.dashboards", {
        id: d.key,
        config: {
            ...d,
            component: () => import("../Dashboard"),
            isAllowed: (state) => state.reporting?.reportsById[d.key]?.allowed,
            // isLoading: (state) =>
            //     state.reporting?.status !== "success" && state.reporting?.status !== "failure",
        },
    });
});
