import { cloneDeep, map, reject } from "lodash";

// We don't support 'strata_insights_free' anywhere in our apps, that's why we're mutating
// authData.instances object and rename app_id to strata_insights
export const aiopsAuthDataMutation = (authData, currentInstanceTier) => {
    // Only mutate authData if it's AIOps free
    if (currentInstanceTier !== "free") return authData;

    let mutatedAuthData = cloneDeep(authData)
    const instances = map(reject(mutatedAuthData.instances, inst => inst.app_id === "strata_insights"), inst => {
        if (inst.app_id === "strata_insights_free") {
            inst.app_id = "strata_insights"
            return inst;
        }
        return inst
    })
    mutatedAuthData.instances = __SPARKY_LIBS__.sparkyFramework.indexTsgInstances(instances);
    return mutatedAuthData
}